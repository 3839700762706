import { DownloadPdfPreferences } from './DownloadPdfPreferences';
import { FormBuilderPlaceholder } from '../components/form-builder/FormBuilderTypes';
import { ClientFormSectionStatus } from './ClientFormSectionStatus';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ClientFormShare } from './ClientFormShare';
import { ClientFormUserResponse } from './ClientFormUser';
import { FormType } from './FormTypes';
import { Translations } from './Translation';
import Cache from 'timed-cache';
import { FormDefaultConfiguration } from './ClientFormDefaults';
import { StepTrigger } from '../components/form-builder/FormBuilderTypes';
import { ClientFormStatus } from './ClientFormStatus';
import { ActionTypeNames } from '../components/form/ActionTypes';
import { DateInterval } from './DateInterval';
import { PeriodicReviewConfigForGets } from './ClientForm';
import { Access } from './Access';
import { ModuleTemplate } from './Module';
import { DistributionResponse } from './Distribution';

export type FormListItem = {
  id: string;
  templateId: string;
  code: string;
  version: number;
  subtitle: string;
  progress: number;
  overallProgress: number;
  status: ClientFormStatus;
  statusUtc: string;
  dueDateUtc: string | null;
  archivedUtc: string | null;
  startDateUtc: string | null;
  clientModuleId: string;
  clientModuleSectionId: string;
  templateModuleSectionId: string;
  isAsset?: boolean;
  translations: Translations<'title' | 'description'>;
  type: (typeof FormType)[keyof typeof FormType];
  users: ClientFormUserResponse[];
  majorVersion: number;
  minorVersion: number;
  clientId: string;
  clientName: string;
  isShared: boolean;
  shares: ClientFormShare[];
  isPublic: boolean;
  currentSteps: StepInfoResponse[];
  unlockedSteps: string[];
  riskScore: number;
  totalRiskScore: number;
  createdUtc: string;
  accessType: Access;
  requiresApproval: boolean;
  requiresValidation: boolean;
  templateModuleName: string;
  templateModuleTranslations: ModuleTemplate['translations'];
  referenceCount: number | null;
  attachmentCount: number | null;
  isSystemTemplateForm: boolean;
  effectiveDateUtc: string | null;
  modifiedUtc: string;
  distribution?: DistributionResponse;
} & PeriodicReviewConfigForGets;

export type FormConfig = {
  id: string;
  createdUtc: string;
  modifiedUtc: string;
  deletedUtc: string;
  archivedUtc: string | null;
  version: number;
  isLatest: boolean;
  code: string;
  category?: string;
  sections: FormSection[];
  iconCode: string;
  translations: Translations<'title' | 'publicTitle' | 'publicDescription' | 'predefinedTitle'>;
  isAssociated: boolean;
  associatedSectionTranslations: Translations<'associatedSectionName'>;
  type: (typeof FormType)[keyof typeof FormType];
  requiresApproval?: boolean;
  requiresApprovalSignature?: boolean;
  requiresValidation?: boolean;
  requiresValidationSignature?: boolean;
  answers: Record<string, any>;
  adHocAnswers: Record<string, AdHocAnswerResponse[]>;
  placeholders?: FormBuilderPlaceholder[];
  clientId?: string;
  isOwnedByClient?: boolean;
  periodicReviewConfig?: PeriodicReviewConfig;
  downloadPdfPreferences?: DownloadPdfPreferences;
  isSystem?: boolean;
  templateModuleId?: string;
  templateModuleSectionId?: string;
};

export type PeriodicReviewConfig = {
  goal?: string;
  repercussions?: string;
  initialReviewDate?: Date | string | null;
  initialReviewAmount: number;
  initialReviewInterval: DateInterval;
  recurrenceFrequencyInterval: DateInterval;
  recurrenceFrequencyAmount: number;
  recurrenceEndOccurrences: number;
  recurrenceEndFixedDate?: Date | string | null;
  noticeAmount: number;
  noticeInterval: DateInterval;
  checkList?: PeriodicReviewChecklistItem[];
  translations?: Translations;
};

export type PeriodicReviewChecklistItem = {
  id: string;
  translations?: Translations<'text'>;
};

export type PeriodicReviewChecklistItemInstance = PeriodicReviewChecklistItem & {
  checked: boolean;
};

export type PlaceholderFormat = 'comma' | 'bullet' | 'plain' | 'link' | null;

export type ActionPlaceholderData = {
  actionType: ActionTypeNames;
  // translations: Translations; // Not populated currently
  friendlyName: string;
  dataFormat: PlaceholderFormat;
  answers: any[];
  clientFormIds: string[];
  actionIds: string[];
};

export type FormSection = {
  id: string;
  actions: FormAction[];
  triggerSteps: StepTrigger[];
  triggersNextStep: boolean;
  visible?: string;
  isDraft?: boolean;
  isPublic?: boolean;
  requiresApproval?: boolean;
  translations: Translations<'title'>;
  number?: string; //used for display purposes
  isHiddenFromExport?: boolean;
};

/**
 * Holds info about the next steps to be unlocked
 */
export type StepInfoResponse = {
  id: string;
  title: string;
  translations: Translations;
};

export type FormAction = {
  id: string;
  visible: string;
  type: ActionTypeNames;
  data?: Record<string, any>;
  required?: string;
  noninteractive?: boolean;
  translations?: Translations;
  previewHidden?: boolean;
  riskEnabled?: boolean;
  riskConditions?: string[];
  riskNoMatch?: string;
  shownInOverview?: boolean;
  shownInResourceOverview?: boolean;
  number?: string; //used for display purposes
};

export type FavouriteForm = {
  id?: string;
  templateFormId: string;
  sortOrder: number;
  color: string;
  translations: Translations<'title'>;
  configuration?: FormDefaultConfiguration;
};

export type ActionHighlightInfo = {
  formId?: string;
  sectionId: string;
  sourceId: string;
  isRisk?: boolean;
};

export type GetAllTemplatesRequest = {
  latestOnly?: boolean;
  includeArchived?: boolean;
  includeActive?: boolean;
  types?: (typeof FormType)[keyof typeof FormType][];
  searchTerm?: string;
  clientModuleId?: string;
};

export type FormTemplatePagedFilter = {
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
} & GetAllTemplatesRequest;

export type FormTemplateConfigurationPagedFilter = {
  moduleId?: string;
} & FormTemplatePagedFilter;

export const formTemplateCache = new Cache({ defaultTtl: 5 * 1000 }); // 5s default Time To Live on cache items

export type FormAnswerResponse = {
  actionId: string;
  data: any;
};

export type AnswerResponse = {
  id: string;
  addedQuestions: FormAction[];
  addedAnswers: FormAnswerResponse[];
  removedQuestions: string[];
  questionRequiredChanged: Record<string, boolean>;
  clientFormProgress: Progress;
  sectionProgress: Record<string, Progress>;
  nextSteps: StepInfoResponse[];
  currentSteps: StepInfoResponse[];
  sectionStatus: ClientFormSectionStatus;
  allStepsValid: boolean;
  clientFormId: string;
  parentSectionStatus: ClientFormSectionStatus;
  placeholders: PlaceholderAnswerResponse;
  data: any;
  clientFormSubtitle: string;
};

type PlaceholderAnswerResponse = { addedLookups: Record<string, ActionPlaceholderData>; removedLookups: Record<string, ActionPlaceholderData> };

export type AdHocAnswerResponse = {
  id: string;
  data: any;
  question: Record<string, any> & { type: ActionTypeNames };
  sortOrder: number;
  actionId: string;
  fieldId: string;
  placeholders: PlaceholderAnswerResponse;
  numberingLevel: NumberingLevel;

  //used for display purposes
  number?: string;
  nextNumbers?: {
    current: string;
    below: string;
  };
};

export enum NumberingLevel {
  None = 0,
  Current = 1,
  Below = 2,
}

export type AdHocFormAnswerRequest = {
  fieldId: string;
  question: Record<string, any> & { type: ActionTypeNames };
  data: any;
  sortOrder: number;
  clientFormId: string;
  actionId: string;
  addedPlaceholders: FormBuilderPlaceholder[] | undefined;
  removedPlaceholders: string[] | undefined;
  numberingLevel: NumberingLevel;
};

export type PublicAdHocFormAnswerRequest = {
  fieldId: string;
  question: Record<string, any> & { type: ActionTypeNames };
  data: any;
  sortOrder: number;
  actionId: string;
  numberingLevel: NumberingLevel;
};

export type Progress = {
  totalAnswers: number;
  totalRequired: number;
};

export type BulkFormAnswerPayload = {
  clientFormId: string;
  answers: BulkFormAnswers[];
};

export type BulkFormAnswers = {
  actionId: string;
  data: any;
};
